.app {
  height: 100%;
  display: flex;
  position: relative;

  main {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: $brand_primary;
    // background-color: #212121;

    .page-main-header {
      padding: 10px 24px;
      background-image: url(https://res.cloudinary.com/net-ninja/image/upload/v1616238571/Net%20Ninja%20Pro/pro-images/pro-banner_rwboej.png);
      .user-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 12px;
        margin-bottom: 10px;
      }
      .search-wrapper {
        button {
          margin-left: 5px;
        }
      }
    }
    .page-title {
      padding: 24px;
    }

    p.page-description {
      color: $brand_yellow;
    }

    .page-menu-header {
      background-color: $submenu-bg-color;
      border: 1px solid $sidebar-color;
      margin-bottom: 10px;
      .btn-menu-list_container {
        // width: 100%;
        display: flex;
        justify-content: space-between;
        // display: grid;
        // grid-template-columns: 2fr 1fr;

        .btn-menu-list {
          min-width: 800px;
          display: flex;
        }
      }
      button:not(:first-child):not(:last-child) {
        margin-left: 10px;
      }
      button:last-child {
        margin-left: 10px;
      }
    }

    .page-content {
      background-color: $submenu-bg-color;
      div {
        overflow-x: hidden;
      }
    }

    footer {
      margin-top: auto;
      color: $brand_heading;
      padding: 0 20px 5px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .contact-link {
        color: $brand_heading;
        font-size: small;
        display: flex;
        align-items: top;
      }
    }
  }
}
