$sidebar-bg-color: #191919;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(29, 29, 29, 0.95);
$sidebar-color: #adadad;
$highlight-color: #fff;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;

$brand_border: 1px solid #252525 !important;
$brand_primary: #252525;
$brand_secondary: #d91b42;
$brand_yellow: #c49b3b;
$brand_heading: #adadad;
$brand_text: #bbbbbb;
$brand_content_bg: #f0f1f6;
$brand_content_text: #171616;

$button-color: #252525;
$button-tint: fadeout(#000, 90%);

@import "~react-pro-sidebar/dist/scss/styles.scss";
@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,400,700&display=swap");
@import "./layout.scss";
@import "./sidebar.scss";
@import "./login.scss";
@import "./work-orders.scss";
@import "./react-paginate.scss";
@import "./modal.scss";
@import "./table.scss";
@import "./form.scss";
@import "./leafygreen.scss";
@import "./queue-print.scss";
@import "./shipment.scss";
@import "./settings.scss";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  height: 100vh;
  font: 14px/1.5 "Poppins", sans-serif;
  color: #fff;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: $sidebar-color;
  border-radius: 20px;
}

#root {
  height: 100vh;
  overflow-y: hidden;
}

.app {
  height: 100vh;
  display: flex;
  position: relative;
}

section {
  display: flex;
  flex-direction: column;
  flex-grow: auto;
  margin: 20px 20px 10px 20px;
  padding: 20px;
  border-radius: 5px;
  height: 100%;
  box-shadow: 5px 5px 10px 10px #111;
  overflow-y: auto;
  overflow-x: hidden;
  &.primary {
    background-color: $brand_content_bg;
    color: $brand_content_text;
  }
  &.transparent {
    color: $brand_text;
  }
}

.card {
  background-color: #fff;
  border-radius: 5px;
  color: $brand_content_text;
  box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075);
}

.warn {
  background-color: #e1bee7;
  font-size: 14px;
}

// helpers
// anker
a {
  color: $brand_content_text;
  text-decoration: none;
  &:hover {
    color: $brand_text;
  }
}

span {
  &.block {
    display: block;
  }
  &.inline-block {
    display: inline-block;
    margin: 0 10px;
  }
  &.link:hover {
    cursor: pointer;
    color: $brand_text;
  }
}

//flex
.d-flex {
  display: flex;
  flex-wrap: wrap;
  &.flex-direction-column {
    flex-direction: column;
  }
}

.d-inline-flex {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
}

.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-space {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
.align-items-start {
  align-items: flex-start;
}

//icon
svg {
  vertical-align: bottom;
}

//text alignment
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}
//padding
.p-0 {
  padding: 0;
}
.p-10 {
  padding: 10px;
}
.ph-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.pv-10 {
  padding-left: 10px;
  padding-right: 10px;
}

//margin
.m-0 {
  margin: 0;
}
.m-10 {
  margin: 10px;
}
.mh-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.mv-10 {
  margin-left: 10px;
  margin-right: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-10 {
  margin-right: 10px;
}

// text case
.text-uppercase {
  text-transform: uppercase;
}
.text-capital {
  text-transform: capitalize;
}

// badge
.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
  &.red {
    color: #ffffff;
    background: #d63030;
  }
  &.gray {
    color: #ffffff;
    background: #777777;
  }
  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}

//title-content-list
.title-content-list {
  display: flex;
  flex-wrap: wrap;
  background-color: $submenu-bg-color;
  border: 1px solid $sidebar-color;
  padding-bottom: 10px;

  .title-content {
    // border: thin solid grey;
    padding: 10px 10px 0 10px;
    span {
      display: block;
    }
    .title {
      font-size: 0.7em;
    }
  }
}

//font size
.font-size-sm {
  font-size: 0.9em;
}
.font-weight-bold {
  font-weight: 600;
}

//action-btn-list
.action-btn-list {
  button:not(:first-child):not(:last-child) {
    margin-left: 10px;
  }
  button:last-child {
    margin-left: 10px;
  }
}

// hidden
.hidden {
  display: none;
}

.scrollbar-thin { 
  scrollbar-width: thin !important;
}

//animation
.swing-icon {
  &:hover {
    svg {
      animation: swing ease-in-out 0.5s 1 alternate;
    }
  }
}

//truncate
.truncate {
  width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// swing icon
@keyframes swing {
  0%,
  30%,
  50%,
  70%,
  100% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
}

@keyframes wiggle {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(30deg);
  }
}

.spinner {
  animation: spin infinite 1s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/** Code for hover info **/

dfn {
  font-style: normal;
  position: relative;
}
dfn::after {
  content: attr(data-info);
  display: inline;
  position: absolute;
  top: 22px;
  left: 0;
  opacity: 0;
  min-width: 80px;
  width: 100%;
  font-size: 12px;
  line-height: 1.5em;
  padding: 0.5em 0.8em;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  pointer-events: none; /* This prevents the box from apearing when hovered. */
  transition: opacity 250ms, top 250ms;
  border-radius: 5px;
}
dfn::before {
  content: "";
  display: block;
  position: absolute;
  top: 12px;
  left: 20px;
  opacity: 0;
  width: 0;
  height: 0;
  border: solid transparent 5px;
  border-bottom-color: rgba(0, 0, 0, 0.6);
  transition: opacity 250ms, top 250ms;
}
dfn:hover {
  z-index: 2;
} /* Keeps the info boxes on top of other elements */
dfn:hover::after,
dfn:hover::before {
  opacity: 1;
}
dfn:hover::after {
  top: 30px;
}
dfn:hover::before {
  top: 20px;
}

//img
img.responsive-img {
  max-width: 100%;
  height: auto;
}

//textarea
textarea {
  border-style: thin;
  border-color: #ddd;
  border-radius: 3px;
  overflow: auto;
}

//msg wrapper
.msg {
  svg {
    vertical-align: center !important;
    margin-bottom: 3px;
  }
}

//search wrapper
.align-right_container,
.search-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.search-select {
  width: 170px;
  min-width: 170px;
  max-width: 170px;
  margin-right: 15px;
}

button.link {
  svg {
    margin: 0;
    color: $brand_text;
  }
}

//date-range
.date-range {
  display: flex;
  align-items: center;
  flex-grow: auto;
  padding-right: 10px;
  margin-right: 10px;
  .title-content {
    // border: thin solid grey;
    span {
      display: block;
    }
    .title {
      font-size: 0.7em;
    }
  }
}

.each-inventory-area {
  display: inline-block !important;
  font-size: 0.8em;
  padding: 1px 5px;
  margin: 2px;
  border: #adadad thin solid;
  border-radius: 5px;
}

.password-wrapper {
  position: relative;
  .fa-password-icon {
    position: absolute;
    top: 30px;
    right: 5px;
    color: $brand_heading;
    &:hover {
      color: $button-color;
      cursor: pointer;
    }
  }
}

//dashboard
.day-refs {
  display: flex;
  div.card {
    width: 50%;
    min-width: 500px;
    min-height: 100px;
  }
}

//print-section
.scroll-to-top-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}

//console-wrapper
.section-sync-orders{
  .console-card{
    display: flex;
    flex-direction: column;
    height: 500px;
    .console-wrapper{
      display: flex;
      flex-direction: column-reverse;
      min-height: 450px;
      background-color: $brand_primary;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    
  }
  span.step-handler:hover {
    cursor: pointer;
    color: $brand_secondary
  }

}

//media print
@media print {
  .hidden-print {
    display: none !important;
  }
}
